/********************************************************************************
 * Copyright (C) 2022 EclipseSource, Lockular, Ericsson, STMicroelectronics and
 * others.
 *
 * This program and the accompanying materials are made available under the
 * terms of the Eclipse Public License v. 2.0 which is available at
 * http://www.eclipse.org/legal/epl-2.0.
 *
 * This Source Code may also be made available under the following Secondary
 * Licenses when the conditions for such availability set forth in the Eclipse
 * Public License v. 2.0 are satisfied: GNU General Public License, version 2
 * with the GNU Classpath Exception which is available at
 * https://www.gnu.org/software/classpath/license.html.
 *
 * SPDX-License-Identifier: EPL-2.0 OR GPL-2.0 WITH Classpath-exception-2.0
 ********************************************************************************/

import { getTheiaCloudConfig } from '@eclipse-theiacloud/common';
import { createApp } from 'vue';
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

const config = getTheiaCloudConfig() || {
  appId: 'asdfghjkl',
  appName: 'ABC',
  useKeycloak: false,
  serviceUrl: 'https://service.qcstud.io',
  appDefinition: '',
  useEphemeralStorage: true
};

const routes: RouteRecordRaw[] = [];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const app = createApp(App, { ...config });
app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});
