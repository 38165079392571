import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SessionLauncher = _resolveComponent("SessionLauncher")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      alt: "Renesas logo",
      src: _ctx.logo
    }, null, 8, _hoisted_1),
    _createVNode(_component_SessionLauncher, {
      serviceUrl: _ctx.serviceUrl,
      appDefinition: _ctx.resolvedAppDefinition,
      email: _ctx.email,
      appId: _ctx.appId,
      useEphemeralStorage: _ctx.useEphemeralStorage,
      workspaceName: _ctx.workspaceName,
      token: _ctx.token
    }, null, 8, ["serviceUrl", "appDefinition", "email", "appId", "useEphemeralStorage", "workspaceName", "token"])
  ], 64))
}