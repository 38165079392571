<template>
  <div>
    <div>{{ text }}</div>
    <div class="loader" v-if="showSpinner"></div>
  </div>
</template>

<script lang="ts">
import {
  LaunchRequest,
  RequestOptions,
  TheiaCloud,
  TheiaCloudError,
  WorkspaceListRequest
} from '@eclipse-theiacloud/common';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SessionLauncher',
  props: {
    serviceUrl: String,
    appDefinition: String,
    email: String,
    appId: String,
    useEphemeralStorage: Boolean,
    workspaceName: String,
    token: String
  },
  created() {
    if (this.email) {
      this.startSession(10);
    }
  },
  data() {
    return {
      text: 'Hang tight while we prepare your next generation development experience! The load will complete momentarily...',
      showSpinner: true
    };
  },
  watch: {
    email() {
      if (this.email) {
        this.startSession(10);
      }
    }
  },
  methods: {
    async startSession(retries: number) {
      const options: RequestOptions = {
        accessToken: this.token,
        retries,
        timeout: 300000
      };
      const appDef = this.$route.query.appDef;
      const requestedAppDef = appDef ? appDef.toString() : this.appDefinition!;
      console.log('Calling to ' + this.serviceUrl);
      console.log('Requesting session for appDefinition: ' + requestedAppDef);
      if (this.useEphemeralStorage) {
        const launchRequest = LaunchRequest.ephemeral(
          this.serviceUrl!,
          this.appId!,
          requestedAppDef,
          undefined,
          this.email
        );
        TheiaCloud.launchAndRedirect(launchRequest, options).catch(error => {
          this.handleLaunchError(error);
        });
      } else {
        const listWorkspaceRequest: WorkspaceListRequest = {
          appId: this.appId!,
          user: this.email!,
          serviceUrl: this.serviceUrl!
        };
        const workspaces: any[] = await TheiaCloud.Workspace.listWorkspaces(listWorkspaceRequest, options);
        const existingWorkspace = workspaces.find(workspace => workspace.appDefinition === requestedAppDef);
        let workspaceName;
        if (existingWorkspace) {
          console.log(`Use existing workspace ${existingWorkspace.name}`);
          workspaceName = existingWorkspace.name;
        } else {
          const createWorkspaceRequest = {
            appId: this.appId!,
            appDefinition: requestedAppDef,
            user: this.email!,
            serviceUrl: this.serviceUrl!
          };
          const createdWorkspace = await TheiaCloud.Workspace.createWorkspace(createWorkspaceRequest, options);
          console.log(`Created new workspace ${createdWorkspace.name}`);
          workspaceName = createdWorkspace.name;
        }
        const startSessionRequest = {
          appId: this.appId!,
          user: this.email!,
          appDefinition: requestedAppDef,
          workspaceName: workspaceName,
          serviceUrl: this.serviceUrl!
        };
        const url = await TheiaCloud.Session.startSession(startSessionRequest, options);
        console.log(url);
        // Redirect
        location.replace(`https://${url}`);
      }
    },
    handleLaunchError(error: any) {
      this.showSpinner = false;
      console.error(error);
      if (error instanceof TheiaCloudError) {
        let userMessage;
        switch (error.status) {
          case 520:
            userMessage =
              'Thanks for your interest in Quick-Connect Studio. The server could not launch your session within the time limit. Please try again in a little while.';
            break;
          case 552:
            userMessage =
              'Thanks for your interest in Quick-Connect Studio. Currently all available developer slots are full. Please try your login request in a little while';
            break;
          case 401:
            userMessage = 'Error 401: You are not authenticated. Please login to use Quick-Connect Studio.';
            break;
          case 403:
            userMessage = 'Error 403: You are not authorized to use Quick-Connect Studio.';
            break;
          default:
            userMessage = `Thanks for your interest in Quick-Connect Studio. Unfortunately, an unexpected error occurred: ${error.status}: ${error.message}`;
        }
        this.text = userMessage;
      } else {
        this.text = error;
      }
    }
  }
});
</script>

<style scoped>
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0882ff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
